import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaShare } from 'react-icons/fa';
import '../styles/EventDetails.css';
import { eventsData } from '../data/eventsData';

const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const EventImages = importAll(require.context('../assets/EventCards', false, /\.(png|jpe?g|svg)$/));

const EventDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const eventData = eventsData.find(e => e.id === parseInt(id));
    if (eventData) {
      setEvent({
        ...eventData,
        image: EventImages[eventData.image] || ''
      });
    } else {
      navigate('/events');
    }
  }, [id, navigate]);

  if (!event) return <div>Loading...</div>;

  return (
    <div className="event-details-container">
      <div className="event-image-container">
        <img src={event.image} alt={event.title} className="event-image" />
        <div className="event-image-overlay">
          <h1 className="event-title">{event.title}</h1>
        </div>
      </div>
      
      <div className="event-info-grid">
        <div className="left-column">
          <div className="info-box date-time-box">
            <div className="date-section">
              <span className="info-icon">📅</span>
              <div className="info-content">
                <h3>Date</h3>
                <p>{event.date}</p>
              </div>
            </div>
            <div className="time-section">
              <span className="info-icon">🕒</span>
              <div className="info-content">
                <h3>Time</h3>
                <p>{event.time}</p>
              </div>
            </div>
          </div>
          <div className="info-box location-box">
            <span className="info-icon">📍</span>
            <div className="info-content">
              <h3>Location</h3>
              <p>{event.location}</p>
            </div>
          </div>
        </div>
        <div className="right-column">
          <div className="info-box about-box">
            <span className="info-icon">ℹ️</span>
            <div className="info-content">
              <h3>About This Event</h3>
              <p>{event.description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
