import React, { useState } from 'react';
import '../styles/SermonsPage.css';

// Import all images from the YtThumbnails directory
const images = require.context('../assets/YtThumbnails', false, /\.(svg|png|jpe?g)$/);

// Access images using their filenames
const standImage = images('./stand.svg');
const bloodImage = images('./intheblood.svg');
const bounceImage = images('./bounce-back.svg');
const luminosityYT = images('./luminosityYT.svg');
const workYT = images('./work.svg');

const SermonsPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  const todaysSermon = {
    // title: "Men's Service",
    image: luminosityYT,
    videoId: "fwQPHO4h95Q"
  };

  const recentSermons = [
    { title: "Intensity of Your Illuminosity", preacher: "Dr. Reginald Blake", image: luminosityYT },
    { title: "Work For Night is Coming", preacher: "Pastor Judith Esdaille", image: workYT },
    { title: "Stand on Business", preacher: "Minister Cathy Ricks", image: standImage },
    { title: "It's In The Blood", preacher: "Pastor Tanya Jenkins", image: bloodImage },
    { title: "You Will Bounce Back", preacher: "Minister Carissa Southwell", image: bounceImage },
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % recentSermons.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + recentSermons.length) % recentSermons.length);
  };

  return (
    <div className="sermons-page">
      <h1>Sermons</h1>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search sermons..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="todays-sermon">
        <img src={todaysSermon.image} alt={todaysSermon.title} />
        <div className="play-button" onClick={() => {
          const iframe = document.querySelector('.youtube-embed');
          const overlay = document.querySelector('.sermon-overlay');
          const playButton = document.querySelector('.play-button');
          
          iframe.src = `https://www.youtube.com/embed/${todaysSermon.videoId}?autoplay=1`;
          iframe.style.display = 'block';
          overlay.style.display = 'none';
          playButton.style.display = 'none';
        }}></div>
        <div className="sermon-overlay">
          {/* <span>MCPC Worship Experience</span> */}
          <h2>{todaysSermon.title}</h2>
        </div>
        <iframe 
          className="youtube-embed"
          width="560" 
          height="315" 
          src={`https://www.youtube.com/embed/${todaysSermon.videoId}`}
          title="YouTube video player" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          referrerPolicy="strict-origin-when-cross-origin" 
          allowFullScreen
          style={{display: 'none', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
        ></iframe>
      </div>
      <h2>Recent Sermons</h2>
      <div className="recent-sermons-slider">
        <button className="slider-arrow left" onClick={prevSlide}>&lt;</button>
        <div className="recent-sermons" style={{ transform: `translateX(-${currentIndex * 320}px)` }}>
          {recentSermons.map((sermon, index) => (
            <div
              key={index}
              className={`sermon-card ${index === currentIndex ? 'active' : ''}`}
            >
              <img src={sermon.image} alt={sermon.title} />
              <div className="sermon-card-content">
                <h3>{sermon.title}</h3>
                <p>{sermon.preacher}</p>
              </div>
            </div>
          ))}
        </div>
        <button className="slider-arrow right" onClick={nextSlide}>&gt;</button>
      </div>
    </div>
  );
};

export default SermonsPage;
