import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/Header.css';
import logo from '../assets/logo.svg';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <a href="/" className="logo-link">
        <div className="logo-container">
          <img src={logo} alt="Church Logo" className="logo" />
          <div className="church-name">
            <div>MOUNT CARMEL</div>
            <div>PENTECOSTAL CHURCH, INC.</div>
          </div>
        </div>
      </a>
      <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav className={`nav-container ${isMenuOpen ? 'open' : ''}`}>
        <ul>
          <li><NavLink to="/" end onClick={toggleMenu}>HOME</NavLink></li>
          <li><NavLink to="/about" onClick={toggleMenu}>ABOUT</NavLink></li>
          <li><NavLink to="/sermons" onClick={toggleMenu}>SERMONS</NavLink></li>
          <li><NavLink to="/events" onClick={toggleMenu}>EVENTS</NavLink></li>
          <li><NavLink to="/tithes-offering" onClick={toggleMenu}>GIVE</NavLink></li>
          <li><NavLink to="/shop" onClick={toggleMenu}>SHOP</NavLink></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
