export const eventsData = [
    { id: 1, title: "Sunday Worship", category: "Worship Service", date: "May 7", time: "10:00 AM", inPerson: true, online: true, image: "church-service.svg" },
    { id: 2, title: "Prayer Breakfast", category: "Weekend Event", month: "October", inPerson: true, online: false, date: "October 26", time: "9:00 AM", image: "prayer-breakfast.svg" },
    { id: 3, title: "Women's Wednesday", category: "Zoom Meeting", month: "November", inPerson: false, online: true, date: "November 6", time: "7:30 PM", image: "ww.svg" },
    { id: 4, title: "MCPC Gala", category: "Dinner Celebration", month: "December", inPerson: true, online: true, date: "December 13", time: "8:00 PM", image: "gala.svg" },
    { id: 5, title: "Candlelight Cantata", category: "Youth", month: "December", inPerson: true, online: false, date: "December 20", time: "7:00 PM", image: "candlelight.svg" },
    { id: 6, title: "Christmas Eve Service", category: "Service", month: "December", inPerson: true, online: true, date: "December 24", time: "11:00 AM", image: "christmas-eve.svg" },
    { id: 7, title: "Men's Service", category: "Worship Service", month: "December", inPerson: true, online: true, date: "October 27", time: "11:00 AM", image: "mens-service.svg" },
    { id: 8, title: "New Year's Service", category: "Watch Night Service", month: "December", inPerson: true, online: true, date: "December 31", time: "10:00 PM", image: "new-years-service.svg" },
    { id: 9, title: "Bible Study", category: "Bible Study", month: "December", inPerson: true, online: true, date: "December 31", time: "11:59 PM", image: "bible-study.svg" },
    { id: 10, title: "Prayer Meeting", category: "Prayer Meeting", month: "October", inPerson: true, online: true, date: "October 22", time: "11:00 AM", image: "prayer-meeting.svg" },
];
