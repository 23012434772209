import React from 'react';
import { FaYoutube, FaInstagram, FaFacebook } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  const footerStyle = {
    boxShadow: '0 -5px 5px -5px rgba(0, 0, 0, 0.1)'
  };

  return (
    <footer className="footer" style={footerStyle}>
      <div className="footer-content">
        <div className="footer-sections">
          <div className="footer-section">
            <h3>About</h3>
            <ul>
              <li><a href="#">Leadership</a></li>
              <li><a href="#">Our Ministries</a></li>
              <li><a href="#">FAQs</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Media</h3>
            <ul>
              <li><a href="#">Sermons</a></li>
              <li><a href="#">YouTube</a></li>
              <li><a href="#">Instagram</a></li>
              <li><a href="#">Facebook</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Ministries</h3>
            <ul>
              <li><a href="#">Rays of Sunshine</a></li>
              <li><a href="#">Mens & Women's Ministry</a></li>
              <li><a href="#">Youth Ministry</a></li>
              <li><a href="#">Music & Media</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Connect</h3>
            <ul>
              <li><a href="#">Contact Us</a></li>
              <li><a href="#">Event Calendar</a></li>
              <li><a href="#">Prayer Request</a></li>
              <li><a href="#">Giving</a></li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="copyright">
            © 2024 Mount Carmel Pentecostal Church, Inc.<br />
            3080 White Plains Rd. Bronx, NY 10467 | <span className="phone">(718) 231 9206</span>
          </div>
          <div className="social-icons">
            <a href="#"><FaYoutube size={20} /></a>
            <a href="#"><FaInstagram size={20} /></a>
            <a href="#"><FaFacebook size={20} /></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
