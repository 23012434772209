import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaHandHoldingHeart, FaCalendarAlt, FaPrayingHands, FaEnvelope, FaYoutube, FaInstagram, FaFacebookF } from 'react-icons/fa';
import '../styles/IconSlider.css';

const IconSlider = () => {
  const icons = [
    { name: 'Events', icon: <FaCalendarAlt />, link: '/events' },
    { name: 'Giving', icon: <FaHandHoldingHeart />, link: '/tithes-offering' },
    { name: 'Prayer Events', icon: <FaPrayingHands />, link: '/#' },
    { name: 'Contact Us', icon: <FaEnvelope />, link: '/#' },
    { name: 'YouTube', icon: <FaYoutube />, link: 'https://www.youtube.com/@MOUNTCARMELPC' },
    { name: 'Instagram', icon: <FaInstagram />, link: 'https://www.instagram.com/mcpcconnect/' },
    { name: 'Facebook', icon: <FaFacebookF />, link: 'https://www.facebook.com/mountcarmelpc' },
  ];

  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      slider.scrollLeft = 0;
    }
  }, []);

  return (
    <div className="icon-slider" ref={sliderRef}>
      <div className="icon-slider-inner">
        {icons.map((item, index) => (
          <Link key={index} to={item.link} className="icon-item">
            <div className="icon">{item.icon}</div>
            <div className="icon-name">{item.name}</div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default IconSlider;
