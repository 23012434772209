import React from 'react';
import '../styles/AboutPage.css';

const AboutPage = () => {
  return (
    <div className="about-page">
      <div className="about-content">
        <h1>About Us</h1>
        {/* content */}
      </div>
    </div>
  );
};

export default AboutPage;